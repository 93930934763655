.bottomNav {
    display: flex;
    width: 100%;
    background-color: #333;
    align-items: flex-end;
    z-index: 100;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 3px;
}

.bottomNav__link {
    color: #fff;
    padding: 0.75rem;
    flex-grow: 1;
    text-decoration: none;
    font-size: 0.75rem;
    text-align: center;
    padding-top: 0rem;
    padding-bottom: 0.125rem;
    user-select: none;
}

.bottomNav__link svg,
.bottomNav__link img {
    width: 35px;
    height: 35px;
    display: block;
    margin: 0 auto;
    position: relative;
    padding-bottom: 5px;
}

.bottomNav__link:hover, .bottomNav__link:focus {
    color: #03D5E4;
}

.bottomNav__link:hover svg, .bottomNav__link:focus svg {
    fill: #03D5E4;
}

.bottomNav__link.active {
    color: #03D5E4;
}

.bottomNav__link.active svg {
    fill: #03D5E4;
}

.bottomNav__link--gold:hover, .bottomNav__link--gold:focus {
    color: #d0be3b;
}

.bottomNav__link--gold:hover svg, .bottomNav__link--gold:focus svg {
    fill: #d0be3b;
}

.bottomNav__link--gold.active {
    color: #d0be3b;
}

.bottomNav__link--gold.active svg {
    fill: #d0be3b;
}