.header {
    background-color: #000;
    color: #fff;
    position: fixed;
    width: 100%;
    z-index: 100;
}

.header--gold {
    height: 120px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

.header__top {
    padding: 0.5rem 1.25rem 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__logo {
    object-fit: contain;
    height: auto;
    width: 100%;
    max-width: 180px;
}

.header__goldPass {
    max-width: 330px;
    width: 90%;
    margin: 0 auto;
    display: block;
}

.header__topMessage {
    background-color: #000;
    text-align: left;
    font-size: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 1.675rem;
    padding-right: 1rem;
}

.header__topMenuIcon {
    width: 34px;
    height: 34px;
    margin-left: 1rem;
}

.header__menuIcon {
    border: none;
    background-color: transparent;
    height: 45px;
    width: 45px;
    cursor: pointer;
    color: #fff;
}

.header__menuIcon--black {
    color: #151515;
}

.header__menuIcon.hide {
    display: none;
}

.header__menuIcon img {
    width: 100%;
    height: auto;
}

/* .header__upgrade {
    position: absolute;
    text-align: center;
    display: block;
    left: 0;
    right: 0;
    padding: 5px;
    background: #03D5E4;
    color: #fff;
} */

.header__upgrade,
.header__message {
    padding: 5px;
    background: #03D5E4;
    color: #fff;
    text-align: center;
}