.dashboard__title {
    text-align: center;
    font-weight: bolder;
    margin-top: 1rem;
}

.dashboard__text {
    text-align: center;
}

.dashboard__time {
    margin-top: 1rem;
    text-align: center;
}

.dashboard__qrcode {
    padding: 1rem;
    background-color: #fff;
    user-select: none;
}

.dashboard__qrcode svg {
    display: block;
    margin: 0 auto;
}

.dashboard__deals {
    display: block;
    border-radius: 8px;
    padding: 2rem 1rem;
    background: #03D5E4;
    text-align: center;
    color: #fff;
    margin-top: 1rem;
}

.dashboard__upgrade {
    display: block;
    border-radius: 8px;
    padding: 2rem 1rem;
    background: #d0be3b;
    text-align: center;
    color: #fff;
    margin-top: 1rem;
}

.blue {
    color: #03D5E4;
}