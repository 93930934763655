.login {
    height: 100%;
}

.login__welcome {
    display: block;
    line-height: 1.6;
    text-align: center;
}

.login__button {
    display: flex;
    justify-content: center;
}

.login__error {
    text-align: center;
    margin-top: 1rem;
}

.login__verify {
    text-align: center;
    margin-top: 1rem;
    color: #03D5E4;
}