.heading {
  text-align: center;
  color: #03D5E4;
  font-size: 1.25rem;
}

.dealsContainer {
  margin-top: 2rem;
}

.buttonContainer {
  margin-top: 2rem;
}

.selectContainer {
  text-align: center;
}